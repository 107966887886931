import React, { useState, useRef } from 'react'
import * as styles from './ProductHero.module.scss'
import MarketoPopup from '../MarketoPopup/MarketoPopup';

const ProductHero = props => {
    const {data, theme} = props;
    const containerRef = useRef(null)
    const [formIsOpen, setFormIsOpen] = useState(false);

    let backgroundStyle = { backgroundColor: "#DA437E"};
    if(theme) {
      backgroundStyle = { backgroundColor: theme?.settings?.primaryColor };
    }
    
    return (
        <>
        <div className={styles.heroContainer} ref={containerRef}>
                    <div className={styles.heroImageContainer}>
                        <img className={styles.heroImage} src={data?.heroImage?.asset?.url} alt={data?.solutionName}></img>
                    </div>
                    {data.solutionName !== null ? 
                      <div className={styles.heroInfoContainer}>
                        <div className={styles.heroIconContainer}>
                          <img className={styles.heroIcon} src={theme?.heroSection.icon.asset?.url} alt={theme?.solutionTitle}></img>
                        </div>
                        <h1 className={styles.heroTitle}>
                          {data?.solutionName}
                        </h1>
                        <p className={styles.heroDescription}>
                          {data?.solutionDescription}
                        </p>
                        {data.heroButtonName !== null ?
                          <div className={styles.heroButtonContainer}>
                            <button className={styles.heroButton} style={backgroundStyle} onClick={() => setFormIsOpen(true)}>
                              {data?.heroButtonName}
                            </button>
                          </div>
                          :
                          null
                        }
                      </div>
                      :
                      null
                    }

                </div>
                <MarketoPopup
                  appContainerRef={containerRef.current}
                  isOpen={formIsOpen}
                  setIsOpen={setFormIsOpen}
                  category={data?.solutionCategory}
                  marketoFormID={data?.marketoFormID}
                />
        </>
    )
}

export default ProductHero